.rightListDropDown {
  border: none;
  padding-left: 10px;
}

.dropDown {
  border: none;
}

.readTimeDiv {
  position: absolute;
  bottom: 20px;
}

.summaryDiv {
  text-align: left;
  padding: 0px 15px;
}

.summaryButton {
  border: none;
  background: none;
  font-size: 12px;
  padding-left: 0px;
  font-weight: 600;
  color: #333333;
}

.summaryButton:hover {
  text-decoration: underline;
}

.gtIcon {
  color: #E0291A;
  font-size: 12px;
  padding-left: 2px;
  position: relative;
  font-weight: 700;
}

.summaryModal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  /* padding-top: 74px; */
  /* padding-bottom: inherit; */
  /* left: 0; */
  /* top: 0; */
  /* width: 100%; */
  /* height: inherit; */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  border: 1px solid #e67320;
  border-radius: 6px;
}

/* Modal Content */
.modalContent {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
.closeButton {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.closeButton:hover,
.closeButton:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.playButtonInsideImage {
  position: absolute;
  top: 11px;
  left: 43px;
  background: #333333b3 0% 0% no-repeat padding-box;
  color: #ffffff;
  border: none;
  text-align: left;
  transform: translate(-50%, -50%);
  width: 56px;
  padding: 3px 0px 3px 3px;
  font-size: 11px;
  border-radius: 3px 0px 0px 0px;
}
