.comInput {
  width: 90% !important;
}

.quesSentence {
  font-weight: 500;
}

.quesSpan {
  line-height: 1.5 !important;
}

.audioButton {
  border: none;
  background: none;
  color: #e67320;
  cursor: pointer;
}

.audioButtonListen {
  color: #e67320 !important;
  font-family: Montserrat;
  padding: 5px 15px;
  border: 1px solid #e67320;
  font-size: 14px;
  border-radius: 100px;
  background: #ffffff 0% 0% no-repeat padding-box;
  cursor: pointer;
}

.sentAudioImage {
  padding:2px 20px 0px 5px;
  display: inline;
}

.speakerImage {
  width: 20px !important;
}

.speakerSvg {
  /* padding-right: 3px; */
  width: 16px;
}

.speakerSvg2 {
  /* padding-right: 3px; */
  width: 16px;
}

.speakerSvg22 {
  width: 16px;
}

.recordButton {
  color: #ffffff !important;
  background: #e67320 0% 0% no-repeat padding-box;
  font-size: 14px;
  border-radius: 100px;
  border: none;
  padding: 6px 16px;
  cursor: pointer;
}

.recordingGif {
  padding: 2px 6px 4px 0px;
  width: 14px;
}

.playRecordedAudioDiv {
  border-radius: 50%;
  display: inline;
  margin-left: 14px;
  padding: 4px 6px 5px 6px;
  border: 1px solid #e67320;
}

.playingRecordedButton {
  cursor: pointer;
}

.recordedAccuracy {
  display: inline-block;
  padding-bottom: 20px;
}

.accuracyMessage {
  color: #0079c4;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
}

.processingMsgDiv {
  display: inline-block;
  padding-bottom: 20px;
  color: #0079c4;
}

.processingImg {
  width: 20px;
  padding: 2px 0px 3px 0px;
  margin-right: 5px;
}

.audioLoadingImg {
  width: 20px;
  margin-left: 10px;
}
.testAudioLoadingImg {
  width: 20px;
}

/* Preference Part */

.prefMainDiv{
  height: 59vh;
}

.prefHeaderDiv {
  text-align: center;
  line-height: 1.0;
  font-size: 14px;
}


.accentLangaugeImageDiv {
  color: black;
}

.accentLangaugeImage {
  color: #0079c4;
}

.testAudioButtonListen {
  color: #444444!important;
  font-family: Montserrat;
  padding: 8px 10px;
  border: none;
  border-radius: 3px;
  background: #F7F7F7 0% 0% no-repeat padding-box;
  font-size: 14px;
  cursor: pointer;
}

.audioButtonListen {
  color: #e67320 !important;
  font-family: Montserrat;
  padding: 5px 15px;
  border: 1px solid #e67320;
  font-size: 14px;
  border-radius: 100px;
  background: #ffffff 0% 0% no-repeat padding-box;
  cursor: pointer;
}

.langLabel {
  padding: 0px;
}

.voiceLabel {
  padding: 0px 0px 0px 10px;
}