@font-face {
  font-family: "Montserrat";
  src: url('./assets/fonts/Montserrat-Regular.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
