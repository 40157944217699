
.navbarLevelsHeader {
    background-color: white;
    padding: 0px;
}

.contentLevels {
    padding: 0px 10px;
    margin: 0 !important;
    align-items: center;
    cursor: pointer;
  }