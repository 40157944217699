.optionsButton {
    border: none;
    background-color: #EBEBEB;
    border-radius: 100px;
    width: 24px;
    height: 24px;
}

.TableClass{
    font-size: 13px !important;
    background-color: white;
    margin: 20px 0px 0px 0px;
    border: 1px solid #E0E0E0;
}


.pinContentCombDiv {
    position: relative;
}


.pinContentComb {
   
    background: white;
    
    font-size: 12px;
    font-weight:500;
}




.editContentButton{
    position: absolute;
    z-index: 0;
    border: none;
    color: #101010;
    box-shadow: 0px 0px 14px #00000026;
    text-align: left;
    width:180px;
    flex-direction: column;
    display:none;
    padding:0;
    background-color: #F5F5F1;

}

.editContentButton span{
    padding:10px
}

.editContentButton span:hover{
    background-color: #C6EAEB;
cursor:pointer;
}

.relUUID{
    cursor: pointer;
    color:  #399fa2;
    font-size: 14px;
    font-weight:500;
}

.relUUID:hover{
    text-decoration: underline;
}



