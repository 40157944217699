.noSummaryBlock {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-wrap: nowrap;
}

.readSumFull {
    color: #444444;
    border: none;
    background: #F2F4F5 0% 0% no-repeat padding-box;
    border: 1px solid #DCDFE0;
    margin-bottom: 15px;
    float: left;
}

.rightButtonsDiv {
    display: flex;
    flex-direction: column;
}

.pinContentCombDiv {
    position: relative;
}
.downArrowIcon {
    color: #e67320 ;
    padding-left: 2px;
    border-left: 1px;
}

.pinContentComb {
    color: #424242 ;
    border: 1px solid #42BABD;
    font-size: 12px;
    font-weight:500;
    border-radius:0;
}

.pinContentComb:hover {
    background-color: #C6EAEB ;
}

.editContentButton{
    position: absolute;
    z-index: 0;
    border: none;
    color: #424242;
    box-shadow: 0px 0px 14px #00000026;
}
.editContentButton:hover{
    color: #42BABD;
}
.exerciseButton{
    color: green;
    margin-top: 10px;
}


.summaryDiv {
    text-align: left;
    padding: 15px 0px
}



.gtIcon {
    color: #e67320;
    font-size: 12px;
    padding-left: 2px;
    position: relative;
    font-weight: 700;
}

.relatedNewsDiv{
    position: relative;
    background-color: #424242;
    color: white;
    width: 99.5vw;
    left: calc(-50vw + 50%);
}