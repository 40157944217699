.preferenceMainDiv {
  border: 1px solid #0000001a;
  border-top: none;
  border-bottom: none;
}

.prefHeader {
  padding: 16px;
}

.prefContainer {
  border: 1px solid #e5e5e5;
}

.accentDiv {
  padding-top: 20px;
  padding-left: 16px;
}

.accentH {
  font-size: 15px;
  font-weight: 600;
}

.accentLanguagesDiv,
.accentVoiceDiv {
  width: 304px;
  margin-bottom: 30px;
}

.dropDownLabel {
  margin-bottom: 10px;
  font-size: 12px;
}

.accentLanguagesIcon {
  float: right;
}

.audioButtonListen {
  color: #e67320 !important;
  font-family: Montserrat;
  padding: 5px 10px;
  border: 1px solid #e67320;
  font-size: 14px;
  border-radius: 100px;
  background: #ffffff 0% 0% no-repeat padding-box;
  cursor: pointer;
}

.speakerSvg {
  /* padding-right: 3px; */
  width: 16px;
}

.speakerSvg2 {
  /* padding-right: 3px; */
  width: 16px;
}

.audSpan {
  display: inline;
}

.updateDiv {
  border: 1px solid #ccc;
  height: 12vh;
}

.updateSubDiv {
  color: black;
}

.updateUl {
  list-style: none;
  float: right;
}

.updateLink {
  color: #ffffff;
  background: #e67320;
  margin: 16px 16px;
  border-radius: 100px;
  padding: 9px 25px;
  pointer-events: none;
  opacity: 0.5;
}

.cancelLink {
  color: #e67320;
  cursor: pointer;
}

.updateLink,
.cancelLink {
  display: inline-block;
}

.updateMessageDiv {
  padding: 10px 25px;
  display: none;
}

.updateMessage {
  color: black;
}

.failureUpdateMessage {
  background: #f8d7da;
  color: #721c24;
  padding: 10px 20px;
  border-radius: 5px;
}

.successUpdateMessage {
  background: #d4edda;
  padding: 10px 20px;
  color: #155724;
  border-radius: 5px;
}

/* .failureUpdateMessage,
.sucessUpdateMessage {
  border-radius: 5px;
} */

.infoIcon {
  padding-right: 10px;
}

.audioLoadingImg {
  width: 20px;
}