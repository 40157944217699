body {
  font-style: "Montserrat", sans-serif;
}

/* css header */

.edge_menu {
  background:#333333;
  height: 76px;
  padding: 0;
  position: sticky;
  top: 0px;
  z-index: 99999;
}

.edge_menu .centering {
  max-width: 1200px;
  margin: 0 auto;
}

.edge_menu .container {
  display: flex;
  align-items: center;
}
.container {
  max-width: 1224px;
  margin: 0 auto;
}

#span_9 .container-fluid{
padding-right:0px;
}


#logos-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#logos-wrap .logos {
  max-width: 140px;
  margin-right: 20px;
  width: 140px;
}

.globalSettings {
  font-family: "Montserrat", sans-serif;
  margin-left:auto;
  font-weight:500;
  position:relative;
}

 .mySolution a:hover,.reorderIcon a:hover {
  padding-bottom: 25px;
    border-bottom: 4px solid;
    border-color: red;
}

/* . a:hover{
  background-color: #C6EAEB;
} */

.globalMenu{
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0 !important;
  padding:0;
  color:#ffffff !important;
}

.mySolution{
margin:0 25px;
font-size:14px;
cursor:pointer;
}

.solutions-menu {
  right: -47px !important;
    left: auto;
    top: 45px;
    min-width: 100px;
}

.dropdown-menu {
  background-clip: padding-box;
    background-color: #FFFFFF;
    border-radius: 0px;
    box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
    list-style: none outside none;
    position: absolute;
    z-index: 56;
    width: 175px;
}





.solutions .solutions-menu > ul {
  flex-direction: column;
  margin-left: 0;
}





.solutions .solutions-menu > ul li {
  margin: 0 13px;
  width: 100%;
}

.globalMenu li {
  position: relative;
}

.liststyle li {
  list-style-type: none;
}



.solutions .solutions-menu > ul li a {
  color: #101010 !important;
  text-align: left;
  padding: 0 10px;
  white-space: nowrap;
  display: block;
  line-height: 35px;
}

.globalMenu li a {
  font-size: 14px;
  margin: 0px;
  color: #101010;
  font-weight: 500;
  
}



.arrow_box {
  position: absolute;
    background: #fff;
    overflow: visible !important;
    right: -76px;
    border-radius: 0px;
    top: 50px;
    padding: 10px 0;
    min-width: 150px;
    display: none;
    box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
}

.settingMenu_dropdown li {
  margin: 10px 0;
}

.settingMenu_dropdown li a, .preferenceSpan {
  margin: 0px auto;
  padding: 0 6px;
  font-size: 14px;
  display: flex;
  align-items: center;
  line-height:35px;
}


.span_12,
.col1 .container,
.row {
  width: 100%;
  float: left;
}

.edge_menu .logos img {
  display: inline;
  float: none;
  height: 36px;
  vertical-align: middle;
  width: 140px;
}

.logos a {
  display: inline-block;
  float: left;
  line-height: 76px;
 }

 .logos a:hover{
   cursor:pointer;
   background-color: transparent;
 }

/* css for body */
.row-fluid.container {
  min-height: 100%;
}

.container {
  max-width: 1224px;
  margin: 0 auto;
}

.clearfix {
  *zoom: 1;
}

.newsfeedParent{
  background-color: #f5f5f1;
}

.welcomeContent {
  background-color: #fff8f5;
  font-size: 14px;
  padding: 30px;
  line-height: 25px;
  font-family: Montserrat !important;
  background-position: right bottom;
  background-repeat: no-repeat;
}

 .closeBtn {
  float: right;
  cursor: pointer;
  font-size: 19px;
  font-weight: 500;
  color: #444444;
}

#subHeader {
  width: 100%;
}

.navbar {
  padding: 0px;
  flex-direction: row-reverse;
}



.subHeader1 {
  width: 50%;
}

.leftColumn {
  float: left;
  text-align: left;
} 

.rightColumn {
  float: right;
  text-align: right;
}


.aEdit div{
  float:left;
  clear:none;

}

.aEdit{
  overflow: hidden;
}

.aEdit p, .bEdit p,.cEdit, .explanationDiv{
  font-size: 11px;
  top: 24px;
  position: relative;
  background: #F5F5F1;
  width: 30px;
  left: 10px;
  color:10px;
  font-weight: 500;
  color:#686868;
}

.aEdit select, .aEdit input{
  width: 280px;
    height: 51px;
    background: #F5F5F1 0% 0% no-repeat padding-box;
    border: 1px solid #ADADAD;
    border-radius: 3px;
    font-size: 14px;
    color: #424242;
    font-weight: 500;
}

.bEdit ._1EEDX, .bEdit ._3sXmF, .explanationDiv ._1EEDX, .explanationDiv ._3sXmF{
  width: 976px;
    height: 51px;
   background: #F5F5F1 0% 0% no-repeat padding-box;
    border: 1px solid #ADADAD;
    border-radius: 3px;
    opacity: 1;
    font-size:14px;
    padding-top:15px
}

._3sXmF:hover, ._ygkSb:hover{
  background: #F5F5F1 0% 0% no-repeat padding-box;
}


.dEdit div{
  background-color:#F5F5F1 ;
}
/* levels SubHeader like Lower Intermediate etc*/
.levelsSubHeader {
  list-style-type: none;
  display: flex;
  text-align: left;
  padding: 0;
  align-items: center;
  list-style-type: none;
  /* margin: 0 !important; */
  padding:0;
}

.contentLevels {
  padding: 15px;
  border: none;
  background: none;
  margin: 0 !important;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  font-size:14px;
}




/* Search Field position */
#search {
  text-align: right !important;
  margin-left: auto;
  margin: 0 !important;
  padding: 0;
}
.searchBox {
  width: 250px;
  border: 1px solid #d8d8d8;
  font-size: 13px;
  height: 30px;
  padding-left: 10px;
  font-family: "Montserrat", sans-serif;
  border-radius:0px ;
  float: right;
  border-right:none;
}


 /* .fa-reorder:hover{
  color:#e67320;
} */

.searchBtn {
  background-color: #f2f4f5;
  border: 1px solid #d8d8d8;
  width: 35px;
  height: 30px;
  padding-bottom: 3px;
  border-radius:0px;
  float:right;
}

.fa-search {
  color: #888;
}

.newsBeta{
 background-color: #FFFFFF;
}


.newsBeta select {
  float: right;
  width: 146px;
  height: 30px;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  border-radius: 3px;
  color: #444444;
  background-color: #f7f7f7;
  border: none;
}

.fa-reorder, .fa-th, .fa-arrow-left {
  cursor: pointer;
}



#newsFeed .span_3 {
  width: 20%;
  max-width:20%;
  float: left;
  font-size: 14px;
  color: #333333;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  

}

.applicationContentView{
  width:100%;
}

.span_3 .fa {
  float: right;
  font-size: 15px;
}
.span_3 ul {
  list-style-type: none;
  padding: 0;
  line-height: 1.5em;
}

.span_3 li a {
  padding: 10px;
  cursor: pointer;
  display:block;
}

.span_3 li a{
  color: #333333;
  border-left: 4px solid;
    border-color: transparent;


}
.span_3 li:hover{
  background-color:#FFF8F5;
}
#newsFeed .span_9 {
  width: 80%;
  float: left;
  font-family: "Montserrat", sans-serif;
}

#reorder {
  display: none;
  float: left;
  padding: 7px 10px 0px 2px;
  cursor: pointer;
}

#newsFeed {
  /* overflow: hidden; */
  width: 100%;
}



.imageContainer {
  position: relative;
  padding: 0px;
  margin-bottom: 30px;
  cursor: pointer;
}

.imageContainer img,
.thumbnail img ,
.thumbnaill img {
  border-radius: 3px;
  width: 100%;
  object-fit: cover;
}

.thumbnail {
  padding: 0;
  margin-bottom: 30px;
  cursor: pointer;
}
.thumbnaill {
  padding: 0;
  cursor: pointer;
}

.thumbnailImage {
  text-align: left;
  padding: 0px 15px;
}

.text-block {
  position: absolute;
  bottom: 0px;
  /* left: 0px; */
  color: #e0e0e0;
  padding: 0 13px 10px 13px;
  font-size: 11px;
  background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
  width:96%;
}

.thumbnailDetails {
  font-size: 11px;
  color: #555555;
  margin-top: 4px;
}

.relatedNewsColor{
  color: #ffffff !important;
}

.category-active, .category-active:hover, .category-active a {
    font-weight: 500;
    background-color: #EBEBE6;
    border-color: #E0291A !important;
}

.loadMore {
  /* color:#007BD6; */
  font-size: 14px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  padding-bottom: 25px;
  /* font-weight:600; */
}

.loadMore button {

  padding: 4px 14px;
  border: 1px solid #42BABD;
  background: border-box;
  color: #424242;
  font-weight: 600;
    
}

.loadMore button:hover{
background-color: #EBEBE6;;
}



.endOfRecords {
  color: green;
}

.modal-header{
  background: #F7F7F7;
}



#previewModal,
#searchPage {
  /* border: 1px solid #cccccc; */
  font-family: "Montserrat", sans-serif;
  border-bottom: none;
  /* overflow: hidden; */
  padding: 0;
  /* display:none; */
}
#searchPage {
  border: transparent;

}

#leftModal {
  /* float: left; */
  /* width: 70%; */

  min-height: 100vh;
  padding: 0 15px;
}
 


.loaderContainer{
  overflow:hidden;
  min-height:700px;
  position: relative;
}

.word {
  cursor: pointer;
}


#leftModal img {
  width: 100%;
  object-fit:cover;
}

/* Uncomment to Remove Make the Scrollbar as transparent */
/* ::-webkit-scrollbar {
  width: 0px;
  background: transparent;
} */

.modalTitle {
  font-size: 24px;
  color: #333;
  font-weight: 600;
  padding-bottom: 15px;
  width:100%;
}

.modalDate {
  font-size: 12px;
  color: #555555;
  width:100%;
  padding-bottom: 15px;
}

.modalContent {
  font-size: 16px;
  color: #000;
  text-align: left;
  line-height: 1.75;
  padding: 20px 0;
}

sup{
  color: #808080;
  font-size: 85%;
  font-weight: 600;
}


.modal-content,
.modal-footer {
  border: transparent;
}

/* Summary Modal  */
/* .modal-content {
  width: 80%;
  left: 20%;
  overflow-y: scroll;
  position: absolute;
  top: 10%;
} */

.modal-content {
  height: 100%;
  overflow-y: scroll;
}
.takeExcersise {
  text-align: center;
  font-size: 15px;
  color: #000000;
  font-weight: 600;
  line-height: 1.8;
  margin-top: 12px;
  
}

.exerBtn {

    font-size: 14px;
    background-color: #42BABD;
    padding: 5px 15px;
    border: none;
    font-weight: 600;
}



#rightModal {
  /* position: fixed; */
  position: sticky;
  top:6.0rem;
  max-width: 15% ;
  padding: 0 15px;
  font-size: 12px;
  margin-top: 60px;
}

.exerBtn1 {
  border: 1px solid #42BABD;
    border-radius: 0px;
    font-size: 12px;
    padding: 5px 15px;
    cursor: pointer;
    font-weight: 500;
}




#content {
  padding: 0 3rem;
  font-size: 14px;
  color: #2c2c2c;
}

.dictMean {
  background-color: #EBEBE6;
  width: 260px;
  height: 330px;
  padding: 10px;
  overflow-y: scroll;
}

.dictMean p{
  /* width:255px; */
  overflow-wrap: break-word;
  white-space: pre-wrap;
  /* word-break: break-all; */
}

.fa-book {
  font-size:14px;
}

.RelatedNews {
  padding: 40px 15px;
  font-size: 14px;
  color: #2c2c2c;
  font-weight: 600;
}
.popular{
    padding:0px 0px 15px 0px;
  display:flex;
  flex-direction:row;
   align-items: center;
}

.textTitle{
  font-size:20px;
  color:#444444;
  font-weight:500;
  padding-right:10px;
}

.divider{
  flex-grow:0.95;
  height: 1px;
  background-color: #444444;
}

.tags {
  font-size: 12px;
  color: #444444;
  margin-right: 5px;
  padding: 4px 10px;
  border-radius: 2px;
  font-weight: 500;
  background-color: #E1E1D8;
}

#exerciseModal {
  background-color: rgba(0, 0, 0, 0.54);
  display: block;
}

#exerName img{
  padding-right:8px;
  cursor: pointer;
}
.exerciseModal {
  margin-top: 90px;
  max-width: 1008px;
  display: block;
  font-family:"Montserrat", sans-serif;
}

ul.category li.selected {
  color: white;
  font-weight: 500;
  background-color: #f47321;
}

.modalHead {
  font-size: 18px;
  padding: 23px 29px 25px 0px;
  background-color: white;
  color: #2c2c2c;
  font-weight: 600;
  border-bottom: transparent;
}

.searchImage {
  float: left;
  margin-right: 10px;
}

.searchImage img {
  border-radius: 3px;
}

.searchContent {
  font-size: 13px;
  color: #444444;
  margin: 8px 0 0 130px;
  text-align: left;
}

.exerQuote{
font-size:14px;
font-weight: 500;
font-family: "Montserrat", sans-serif;
margin-bottom: 20px;

}

.resultNos {
  font-size: 12px;
  padding: 15px 150px;
  font-weight: 600;
}

textarea:focus,
input:focus,
button:focus {
  outline: none;
}

.searchPage {
  margin: 0 123px;
}

.previewModal {
  /* overflow: hidden; */
  display: flex;
  padding-bottom: 30px;
  min-height: 100vh;
}

.previewModal div{
  float:left;
  clear:none;
}

/* css for footer  */

.footer {
  
     
      color: #333 !important;
      text-align: center;
      line-height: 25px;
      background-color: #fff;
      position: relative;
}

.footer p {
  font-size: 10px;
}


 .footer .footLogo{
      width:90px;
      height:36px;
  }

 
  .footer a{
  text-decoration: none;
  background-color: transparent;
  color: #7A7A7A !important;
  font-weight: normal;
  margin-right: 15px !important;
  font-size:12px;
 }

 .footer  ul{
     list-style-type: none;
     display:flex;
     margin-bottom:0px;
 }


.content {
  padding: 6px 10px;
    color: #333;
    background: #fff;
    font-size: 10px;
    font-family: "Montserrat",sans-serif;
    display: flex!important;
    align-items: center;
    justify-content: space-between;
    max-width: 1220px;
    margin: 0 auto;
}

.default-pipe {
  border-left: solid #777 1px;
  padding-left: 5px;
  margin-left: 5px;
}

.content span a {
  white-space: nowrap;
  color: #444;
}

a,
a:visited,
a:hover {
  text-decoration: none;
}

.category a:hover{
  background-color: #EBEBE6;
}



/* NewsFeed Starts */

.playButtonInsideImage {
  position: absolute;
    top: 76px;
    left: 125px;
    background-color: rgba(0,0,0,0.7);
    border: none;
    cursor: pointer;
    text-align: center;
    transform: translate(-50%, -50%);
    width: 37px;
    padding: 5px 0px;
    font-size: 12px;
    border-radius: 3px;
  }

  .image1{
    top: 163px;
    left: 357px;
  }
  span.fa.fa-play {
    color: rgb(255, 255, 255);
  }

  .videoCategoryImage:hover  .playButtonInsideImage {
    background: #c4302b;
}

  .duration{
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size:12px;
    color:#000000;
    margin-left:5px;
  }
/* NewsFeed Starts */

/* Modal Component Starts here */
.contentGap {
  padding: 20px 0px;
}
.tagsButton{
  border: none;
}

/* Modal Component Ends here */

/* Loader Component Starts */
.mainLoader {
  position: absolute;
  width: 100%;
  height: 50%;
  background-color: rgba(0, 0, 0, 0);
  background-image: url("./assets/loader-log.gif");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70px;
}

.loaderTrial .mainLoader{
height:100%;
opacity:0.2;
background-color: black;
}

.mainLoader1 {
  position: absolute;
    width: 76%;
    height: 73%;
    /* background-image: url(/static/media/loader-log.04c6652c.gif); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70px;
    background-color: white;
}

.feedContainer {
  min-height: 800px;
  display: flex;  
  }

.loginLoader{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  background-image: url("./assets/loader-log.gif");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100px;
}
/* Loader Component Ends Here */

/* Exercise Component Starts here */
/* Exercise Component Starts here */
/* Exercise Component Starts here */
/* Exercise Component Starts here */
.exerPreferenceDiv {
  height: 59vh;
  padding: 2rem 6rem;
}

.exerciseInput {
  border-radius: 10px;
  padding: 1px 0px 1px 10px;
  margin: 5px 9px;
  border: 1px solid #a9a9a9;
}
.exerciseInput:focus-within {
  border-color: #df7727;
}

.moreQuestions {
  font-size: 14px;
  color: #424242;
  cursor: pointer;
  border: none;
  background: none;
  font-weight: 500;
  padding-right:15px;
}

.moreQuestions:hover, .ShowAns:hover, .summaryButton:hover{
  text-decoration:underline;
}

.tooltipsSkill .tooltiptextsSkill {
  visibility: hidden; 
    width: 184px;
    color: #FFFFFF;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 6px 5px 5px 10px;
    position: absolute;
    z-index: 1;
    margin-top: -16px;
    margin-left: 7px;
    font-family: sans-serif;
    font-size: 11px;
    border-radius: 4px;
    text-align: left;
    }


 .tooltiptextsSkill1 {
  display: none;
    width: 540px;
    color: #333333;
    position: absolute;
    z-index: 1;
    margin-left: 90px;
    font-family: "Montserrat", sans-serif;
    text-align: left;
    top: 21px;
    font-size: 14px;
    line-height: 1.7;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 24px #00000052;
    opacity: 1;
    font-weight:500;
    }



.summaryHeader{
  background: #F7F7F7 0% 0% no-repeat padding-box;
border-radius: 6px 6px 0px 0px;
opacity: 1;
align-content: space-between;
padding:15px;
}

.summaryFooter{
  text-align: right;
letter-spacing: 0px;
color: #E0291A;
opacity: 1;
padding:15px;
}

.exerciseAlertMessage {
  color: green;
}

.CheckAns, .prefContinue {
  padding: 9px 20px;
    font-size: 14px;
    font-weight: 600;
    background-color: #42BABD;
    cursor: pointer;
    color:#424242;
    border: 0px;
}


.CheckAns:hover,.exerBtn1:hover,.exerBtn:hover,.moreQues:hover,.summaryButton1:hover{
background-color: #C6EAEB;
}

.moreQues{
  margin-right: 15px;
  background-color: transparent;
  border: 1px solid #42BABD;
}


.ShowAns {
  font-size: 14px;
  color: #424242;
  cursor: pointer;
  border: none;
  background: none;
  font-weight: 500;
  padding-right:15px;
}



.checkIcon {
  color: "#009CDE";
  padding-left: 7px;
  font-size: 16px;
}

.questionIndex {
  padding: 0px 10px 0px 0px;
  display: inline-block;
}
.questionText {
  display: inline;
}

.questionDiv {
  padding-bottom: 20px;
  font-size:14px;
}
.questionsDiv {
  height: 57vh;
  overflow-y: scroll;
}

/* new exercise modal css */
.quitBtn{
  padding: 5px 10px;
    color: #E40000;
    font-size: 12px;
    font-weight: 600;
    background-color: #F2F4F5;
    border-radius: 2px;
    float:right;
    cursor:pointer;
}

.exerContent{
  padding: 0 2rem;
    font-size: 14px;
    color: #444444;
    font-weight: 500;
    height: 71vh;
    }
 
.exerCategory{
     float: left;
    background-color: #F5F7F7;
    width: 240px;
    margin-right: 20px;
    font-size: 16px;
    font-weight: 600;
    color: #696969;
    padding: 35px 0;
    border-left: 4px solid #E0291A;
    cursor: pointer;
}

.exerCategory:hover{
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
}
.timer{
  background-color: #F5F5F1;
  color: #424242;
    padding: 4px;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    position:sticky;
    top:0px;
    z-index:9999;
   }

.comingSoon{
  color: #696969;
    font-size: 12px;
    font-weight: 400;
    margin-top: 10px;
}

.grayOut{
  opacity:0.5;
  padding:25px 0px;
  pointer-events: none;
}

.questionNumber{
  color: #888888;
    font-size: 13px;
    padding-bottom: 20px;
    font-weight:500;
}

.question{
  color: #444444;
    font-size: 15px;
    padding-bottom: 16px !important;
    font-weight: 500;
    line-height:1.5;
}

.exeQuest .radio {
    color: #444444;
    font-size: 15px;
    font-weight: 500;
    padding-left: 10px;
    margin-bottom: 15px;
    
}

.exeQuest input[type=number]{
  width: 220px;
    height: 50px;
    border-style: none;
    border: 1px solid #777777;
    border-radius: 3px;
    padding-left: 10px;
}

.exeQuest input{
  width: 106px;
    height: 38px;
    border-style: none;
    border: 1px solid #777777;
    border-radius: 3px;
    padding-left: 10px;
}


.imgg{
  padding-right:5px;
}


.timer ul{
  list-style-type: none;
  text-align:center;  
  padding:0;
  margin-bottom:0;
}
.timer li{
  float:none;
  display:inline-block;
  width:20px;
}

/* Chrome, Safari, Edge, Opera */
.exeQuest input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.exeQuest input[type=number] {
  -moz-appearance: textfield;
}
.doneBtn{
border: 1px solid #42BABD;
    color: #42BABD;
    font-size: 12px;
    border-radius: 3px;
    padding: 5px 7px;
    margin-left: 15px;
    cursor: pointer;
}

.resultDiv{
  margin-bottom: 3rem;
  background-color: #F2F4F5;
  text-align: left;
  padding: 25px 0px 15px 70px;
  border-left: 4px solid;
  border-color: #E0291A;
}

.resultDiv .text{
  color: #444444;
    font-size: 12px;
    font-weight: 500;
    padding-bottom: 15px;
}

.resultDiv .score{
    font-size: 48px;
    font-weight: 500;
    padding-bottom: 10px;
    color:#424242;
    
}

.resultDiv .time{
  color: #2C2C2C;
    font-size: 14px;
    font-weight: 500;
    padding-top: 10px;
}

.completedMessage{
  color: #444444;
  font-size: 14px;
  padding: 30px 0;
}

.hint{
  color:#0079C4;
  font-size:12px;
  font-weight: 500;
}

/* Preference Part Start in AllExercise.js */
/* Preference Part Start in AllExercise.js */
/* Preference Part Start in AllExercise.js */

.accentLanguageVoiceDiv{
  display: flex;
  font-size: 13px;
}

.accentLanguage {
  display: flex;
  width: 46%;
  margin: 4% 0% 4% 4%;
  background: #F7F7F7 0% 0% no-repeat padding-box
}

.accentVoice {
  display: flex;
  margin: 4% 0% 4% 4%;
  width: 46%;
  background: #F7F7F7 0% 0% no-repeat padding-box
}

.selectAccentOptionsDiv, .selectVoiceOptionsDiv {
  padding: 23px 12px;;
}

.languageOption {
  margin: 10px 0px 0px 0px;
  cursor: pointer;
}

.voiceOption {
  margin: 10px 0px 0px 0px;
  cursor: pointer;
}
/* Preference Part Start in AllExercise.js */
/* Preference Part Start in AllExercise.js */
/* Preference Part Start in AllExercise.js */


/* Exercise Component Ends here */
/* Exercise Component Ends here */
/* Exercise Component Ends here */
/* Exercise Component Ends here */
/*errorpage css*/

.errorPage, .trialSession{
  min-height:82vh;
  background-color: #FAFAFA;
  position:relative;
}



.errorMessageDiv,.trialSessionDiv{
  font-family: "Montserrat", sans-serif;
  background-color: #FFFFFF;
  box-shadow:0px 1px 4px #0000001F;
  border-radius:4px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  padding:35px 50px;
  position:absolute;
  top:40%;
  left:30%;
  margin:0;

}



.trialSession .link{
  position:absolute;
  top:77%;
  left:33%;
  font-weight: 500;
  font-size:16px;
  padding-left:20px;
}


.trialURL{
  border:1px solid grey;
  padding:10px;
  border-radius: 4px;
  width:300px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  margin-top:5px;
}

.fa-copy{
  font-size: 24px;
  float: right;
  color: grey;
  cursor: pointer;
  padding: 10px;
  border: 1px solid grey;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-top:5px;
  border-left: 0;
}
.trialSessionDiv .saveBtn{
  width: 89px;
    height: 32px;
    background: #E67320 0% 0% no-repeat padding-box;
    border-radius: 2px;
    border: transparent;
    margin-left: 10px;
    color: #FFFFFF;
    font-size: 14px;
    margin-top:15px;
} 
.emailId, .organisation, .countryDropdown{
  padding-top:25px;
}
.trialSessionDiv .emailId input, .trialSessionDiv .organisation input{
  padding-bottom:10px;
  min-width:280px;
}

.requiredError fieldset {
  border: 1px solid #E0291A;
  }
 
  
 .displayNone {
  display: none;
 }


.errMsgtitle{
  font-size:24px;
  font-weight:600;
  color:#404040;
}


.errMsgtitle .fa{
  color:#FBA819;
}

.errorMsg{
  font-size: 14px;
  padding-top: 25px;
  font-weight: 500;
}

.categoryList{
  padding: 0 0 5px 80px;
   margin: 30px 0px; 
   overflow-x: hidden
}
/* mobile responsive */

@media screen and (max-width: 320px) {
  #newsFeed .span_3 {
    font-size: 7px !important;
  }

  .span_3 .fa {
    font-size: 11px !important;
  }
}

@media screen and (max-width: 700px) {
  .previewModal {
    min-height: 175vh;
    flex-direction: column;
    align-items: center;
  }

  .takeExcersise {
    display: none;
  }

  #leftModal {
    width: 100%;
  }

  #rightModal {
    width: 100%;
    margin-top: 10px;
    padding: 0 45px;
    position:unset;
  }

  .exerBtn1 {
    margin-left: 30px;
  }
  .searchBox {
    width: 220px !important;
  }

  .searchPage {
    margin: 0 20px;
  }

  .searchContent {
    margin: 10px 0 0 0px;
  }

  #newsFeed .span_3 {
    font-size: 9px;
    width:100% !important;
  }

  #newsFeed .span_3 p {
    font-size: 7px !important;
    padding: 10px 0 !important;
  }

  .span_3 li {
    padding: 10px 1px;
  }
}

@media screen and (width: 768px) {
  .searchBox {
    width: 500px !important;
  }

  #rightModal{
    width: 24%;
    padding: 0 24px;
  }

  .dictMean{
    width:185px;
    height:400px;
  }

  .dictMean p{
    width:180px;
    overflow-wrap: break-word;
  }
  .thumbnail .thumbnailDetails,
  .thumbnaill .thumbnailDetails {
    margin-left: 0px;
  }

  .thumbnailDetails {
    margin-left: 130px;
  }

  .exerCategory{
    width:176px;
  }
}

/* Preference Start */
/* Preference Start */
/* Preference Start */
/* Preference Start */

.prefMainDiv{
  height: 59vh;
  overflow: scroll;
  overflow-x: hidden;
}

.accentLanguageDiv, .accentVoiceDiv {
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.accentLanguages {
  /* border-bottom: 1px solid #ccc; */
  padding-bottom: 5px;
  cursor: pointer;
  font-size: 14px;
}
.accentLanguages:after {
  border: 1px solid #fff;
  background: #fff;
}

.languagedropdown ,.voiceDropDown{
  position: absolute;
  display: none;
  z-index: 1;
  background-color: rgb(255, 255, 255);
  width: 304px;
  border: 1px solid #ccc;
  box-shadow: 0 3px 11px -2px #33333363;
}

div .langDropDown::after {
  background: #fff;
}

.langOption, .voiceOption{
  font-size: 14px;
  cursor: pointer;
  display: block;
  padding: 5px 8px;
}

div.testVoice {
  margin-bottom: 100px;
}

div.testVoiceInExercise {
  float: right;
  margin: 0px;
}

a.testVoiceLink {
  border: 1px solid #E67320;
  border-radius: 100px;
  padding: 5px 8px;
}

.updatePreferencesLink{
  pointer-events: all !important;
  opacity: 1.0 !important;
  cursor: pointer !important;
}
/* Preference End */
/* Preference End */
/* Preference End */
/* Preference End */

/* communication highlight text start */
.highlightTextBox {
  width: 768px;
  min-height: 40px;
  background: #F4FBFF 0% 0% no-repeat padding-box;
  border: 1px solid #E1F1FA;
  opacity: 1;
  float: left;
  padding: 5px 0px;
  margin: 8px 0px;
}

.highlightText {
  
  text-align: left;
  letter-spacing: 1.8px;
  opacity: 1;
  float: left;
  padding: 0px 15px;
}

.highlightText span {
  border-bottom: 2px dotted #0079C3;
  opacity: 1;
  height: 0px;
  text-align: left;
  font: normal normal 600 15px/25px Montserrat;
  letter-spacing: 1.8px;
  color: #0079C4;
}
/* communication highlight text end */

.summaryButton {
  border: none;
  background: none;
  font-size: 12px;
  padding-left: 0px;
  font-weight: 500;
  color: #333333;
}

.summaryButton1 {
  padding: 5px 0px;
    font-size: 12px;
    font-weight: 600;
    font-weight: 600;
    color: #333333;
    width: 110px;
    background: #F2F4F5 0% 0% no-repeat padding-box;
    border: 1px solid #42BABD;
}




/*Editor*/
.RichTextEditor__editor___1QqIU .public-DraftEditor-content {
  font-size: 14px;
  color: #424242;
  font-family: "Montserrat", sans-serif !important;
  line-height:2;
  background-color: #F5F5F1;
}

.editQuestion{
  padding:0 15px;
}

.editQuestion ._1EEDX._ygkSb,.editQuestion ._1EEDX {
  background: #F5F5F1;
  border:none;
  border-radius: 3px;
  opacity: 1;
  font-size: 14px;
  overflow: visible;
  font-weight:500;
  height:auto !important
}

.editQuestion ._1EEDX._ygkSb:hover, .editQuestion ._1EEDX._3sXmF:hover {
  background-color:#F5F5F1;
}

.editQuestion ._1EEDX{
  border:1px solid orange;
  height:auto !important; 
}

.optionsEdit{
  font-size:14px;
  font-weight:500;
  height:38px;
  overflow: hidden;
}

.optionsEdit .inputOption{
  float: left;
  top: 24px;
  position: relative;
}
.optionsEdit ._1EEDX._3sXmF, .optionsEdit ._1EEDX{
  float:left;
  padding-left:25px;
  font-weight:500;
}

.optionsEdit ._1EEDX{
  border:none;
}

.editContentss{
  font-family: "Montserrat", sans-serif;
  margin-bottom:100px;
}


.error-message {
  color: red;
    font-size: 10px;
    font-weight: 500;
}


.pinLoader{
  position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    background-image:  url("./assets/pincontent-copy.gif");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 42px;
    top: 0;
    left: 0;
}

.pinnedButtonSpan{
width:110px;
color:#42BABD;
pointer-events: none;
}

.pinnedContentsTableDiv .table thead th
{padding: 20px;
    font-size: 11px;
    color: #555555;
    font-weight: 600;
background-color: #F9F9F9;}

.pinnedContentsTableDiv{
padding-bottom: 50px;
}


.deleteDiv{
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
  filter: blur(10px);
}


.switch {
  position: relative;
    display: inline-block;
    width: 32px;
    height: 16px;
    margin-top: 50px;
    margin-left: 20px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 0px;
    top: 3px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}

input:checked + .slider {
  background-color: #E0291A;
}

input:focus + .slider {
  box-shadow: 0 0 1px #E0291A;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.contentChina{
  color: #424242;
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px;
    position: absolute;
    padding-top: 49px;
}


.table td{
  vertical-align: middle !important;
    padding: 5px 15px;
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    font-style: "Montserrat", sans-serif;
}

.fa-check-circle{
  color:#1CA250;
  font-size: 16px;
}


.fa-times-circle{
  color: #BBBBBB;
  font-size: 16px;
}

.fa-trash{
  color: #808080;
    width: 12px;
    height: 15px;
}

.fa.fa-trash:hover{
  color: #E40000;
    background-color: #EDEDED;
    background-size: 24px 24px;
    cursor:pointer;
}


.table-hover tbody tr:hover{
  background-color: transparent;
}

.product-list li a:hover{
  background-color: #C6EAEB !important;
  padding-bottom: 0 !important;
  border:0 !important;
}

.arrow_box li a:hover,.arrow_box a span:hover{
  background-color: #C6EAEB !important;
  padding: 0 6px !important;
  border:0 !important;
}