.rightNav {
    padding-left: 20px;
    color: #ffffff;
    cursor: pointer;
}

.rightNav a:hover{
    padding-bottom: 25px;
    border-bottom: 4px solid;
    border-color: red;
}


.pipeIcon {
    opacity: 0.5;
    /* height: 10px; */
    padding-left: 20px;
}