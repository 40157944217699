.pinnedContentsLabel {
  font-size: 18px;
  font-weight: 600;
  position: relative;
  top: 10px;
  padding-top:30px;
}

.pinnedContents {
  border: none;
  background: none;
  margin-right: 15px;
  font-size:14px;
  color:#939495;
  font-weight: 500;
  padding-bottom:5px;
}



.filterButton {
  border: 1px solid #e67320;
  border-radius: 3px;
  font-size: 12px;
  color: #e67320 !important;
  width:82px;
  height:30px;
  cursor: pointer;
  background-color: white;
  font-weight: 500;
}

.filterIcon {
  padding-right: 10px;
}

.filtersDiv {
  position: absolute;
  font-size: 14px !important;
  height: 263px;
  width: 399px;
  z-index: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  display: flex;
  flex-direction: column;
}

.levCatOptions {
  display: flex;
  flex-direction: row;
  padding-left: 20px;
}

.levelsFilter {
  /* padding:20px; */
  /* position: relative; */
  top: 10px;
}

.levelsCheckboxes {
  display: flex;
  flex-direction: column;
}

.filterCheck {
  margin-right: 10px;
}

.categoriesFilter {
  /* padding:20px; */
  /* position: relative; */
  top: 10px;
}

.categoriesCheckboxes {
  display: flex;
  flex-direction: column;
  height: 150px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 5px;
}

.resetApplyDiv {
  font-size: 14px;
  margin: 25px 30px 0px 0px;
  position: relative;
  align-self: flex-end;
}

.resetButton {
  border: none;
  background: none;
}

.resetButton:hover {
  color: #e67320;
}

.applyButton {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  margin-left: 10px;
}

.pageSizeDropDown {
  padding: 3px 0px 5px 3px !important;
  margin: 2px 4px;
  border: 1px solid green;
  border-radius: 30%;
  width: 50px;
  font-size: 14px;
}

.ButtonStyles {
  display: inline-grid;
  margin-left: -1px;
  font-size: 14px;
  line-height: 30px;
  color: #499be8;
  border: 1px solid #dee2e6;
  background-color: #fff;
  width: 40px;
}

.ButtonStyles:hover {
  color: red;
}

.pageSizeDropDown {
  padding: 3px 0px 5px 3px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  margin: 2px 4px;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  width: 50px;
  font-size: 14px;
}

.pageNumberDropDown {
  padding: 5px 0px 6px 3px !important;
  margin: 2px 3px;
  border: 1px solid rgb(222, 226, 230);
  color: rgb(73, 155, 232);
  width: 45px;
  display: inline-grid;
  font-size: 14px;
}

.paginatingData {
  position: relative;
  top: 10px;
  margin-bottom: 15px;
}

.prevForwardIcons {
  color: #007fb1;
  padding: 8px;
}

.activePage {
  background-color: paleturquoise !important;
}


.contentFooter{
  bottom: 0;
  position: fixed;
  width: 1173px;
  height: 58px;
  background: #424242;
  box-shadow: 0px -1px 10px #3333332e;
  border-radius: 4px 4px 0px 0px;
  z-index:999;

}

.leftbtn{
 float: left;
 padding:15px 0 0 15px;
 font-size: 14px;
}
.rightbtn{
 float:right;
 padding:15px 15px 0 0;
 font-size: 14px;
}

.leftbtn1{
background: transparent;
border-radius: 2px;
opacity: 1;
border: transparent;
color: #FFFFFF;
font-weight: 500;
text-align: center;
height:32px
}

.savebtn{
width: 177px;
height: 32px;
background: #42BABD;
border-radius: 2px;
border: transparent;
margin-left: 10px;
color: #FFFFFF;
}

.editContentsLabel {
  font-size: 14px;
  font-weight: 500;
  position: relative;
  height: 18px;
  letter-spacing: 0px;
  opacity: 1;
  cursor:pointer;
  }

  .editID{
    text-align: left;
    font-weight:500;
    letter-spacing: 0px;
    color: #2C2C2C;
    font-size:18px;
      }

      .editContents {
        border: none;
        background: none;
        padding-right: 20px;
        font-weight:500;
        margin-top:20px;
        padding-bottom:5px;
      }
    
      
      
      .editContents:hover {
        color: #e67320;
      }


      .previewTitle{
        font-size:22px;
        font-weight:600;
        color:#444444;
        padding:15px 0 5px 0;

      }

      .previewContent{
        font-size: 15px;
    color: #424242;
    padding: 15px 0 30px 0px;
    font-weight: 500;
    line-height: 1.7;
      }


      .versionDetails{
            border-radius: 0px;
    background-color: #EBEBE6;
    margin-top: 30px;
    padding:12px;
    float:left
      }

      .versionDetailsText
      {color: #000000;
    font-size: 14px;
    font-weight: 500;}

    .versionNumber{
    background-color: #F5F5F1;
    padding: 5px;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    color: #000000;
    font-weight: 500;
    font-size:14px;
    }

    .versionNumDetails, .versionNumDetails div{
      float:left;
    }

    .versionNumDetails p{
      margin-bottom:0px;
    }

    .ellipses{
      background-color: #EBEBEB;
    width: 24px;
    height: 24px;
    text-align: center;
    border-radius: 100px;
    cursor:pointer;
    }

    .PinnedContentsParent{
      font-family: "Montserrat", sans-serif;
    }

    .pinContentCombDiv {
      position: relative;
  }
  
  
  .pinContentComb {
     
      background: #F5F5F1;
      
      font-size: 12px;
      font-weight:500;
  }
  
  
  
  
  
  .editContentButton{
      position: absolute;
      z-index: 0;
      border: none;
      color: #101010;
      box-shadow: 0px 0px 14px #00000026;
      text-align: left;
      width:180px;
      flex-direction: column;
      display:none;
      padding:0;
     
  
  }
  
  .editContentButton span{
      padding:10px
  }
  
  .editContentButton span:hover,.savebtn:hover,.discardbtn:hover,.discardbtn1:hover{
      background-color: #C6EAEB;;
  cursor:pointer;
  }

  
    .editContents {
      border: none;
      background: none;
      padding-right: 20px;
      font-weight:500;
      margin-top:20px;
    }
  
    
    
    .editContents:hover {
      color: #e67320;
    }
  
  
    .editID{
  text-align: left;
  font-weight:500;
  letter-spacing: 0px;
  color: #2C2C2C;
  font-size:18px;
    }
  
    .toggleContentBtn{
  
      color:#939495;
      font-size:14px;
  
    }
  
  
    
  
   .leftbtn{
     float: left;
     padding:15px 0 0 15px;
     font-size: 14px;
   }
   .rightbtn{
     float:right;
     padding:15px 15px 0 0;
     font-size: 14px;
   }
    
   
  
   
  
   .contentp{
     font-size:11px;
     width:49px;
     background-color:white;
     position: relative;
     top:24px;
     left:9px;
   }
  
   .editDiv{
    display: inline-block; 
    width: 100%;
    padding: 15px 15px 0 0px;
   }
  
   .editParentDiv{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px #3333332B;
    border-radius: 3px;
    margin-top:35px;
    font-size:14px;
   }
  
   .optionNum{
     font-size:16px;
     padding:15px;
     font-weight: 500;
     margin:10px 5px !important;
     height:45px !important;
   }
  
  
   .editQues{
    float: left;
    font-size: 12px;
    color: #424242;
    font-weight: 600;
    overflow: hidden;
  
   }
  
  
   .options{
     padding:10px 15px 0px 20px;
     font-weight:500;
     font-size:12px;
   }
  
   .speakerSvg{
     width:16px;
     cursor: pointer;
   }
  
   .exerciseSpeaker{
    border: 1px solid #E67320;
      padding: 8px;
      border-radius: 100px;
      position: relative;
      top: 45px;
   }
  
   .exerciseSpeaker1{
    color: #E67320;
       font-size: 14px;
       font-weight: 500;
       border: 1px solid #e67320;
       padding: 6px 10px;
       border-radius: 100px;
       margin: 15px;
    }
  
  
    .discardChanges{
      position: absolute;
      top: 40%;
      left:30%;
      background-color: white;
      box-shadow: 0px 3px 7px #0000004d;
      font-family: "Montserrat", sans-serif;
      width:482px;
      border-radius:4px;
      z-index:999;
    }
  
  
    .discardHeader{
      font-size: 18px;
      padding: 15px;
      border-bottom: 1px solid #F7F7F7;
    }
  
    .discardBody{
      font-size: 15px;
      padding: 15px 15px 50px 15px;
      font-weight: 500;
      color: #555555;
    }
  
    .discardFooter{
      display: flex;
      float: right;
      padding: 0 15px 15px 15px;
      width: 482px;
      justify-content: flex-end;
      background-color: #F7F7F7;
    }


    .optionNum{
     width: 226px;
    border: 1px solid #009CDE;
    height: 56px;
    background-color: #F5FCFF;
    border-radius: 3px;
    color: #009CDE;
    }
  
    .gapfiler{
      width: 226px;
    border: 1px solid #009CDE;
    height: 56px;
    background-color: #F5FCFF;
    border-radius: 3px;
    color: #009CDE;
    
    }

    .discardChanges{
      position: absolute;
      top: 40%;
      left:30%;
      background-color: white;
      box-shadow: 0px 3px 7px #0000004d;
      font-family: "Montserrat", sans-serif;
      width:482px;
      border-radius:4px;
      z-index:999;
    }
  
  
    .discardHeader{
      font-size: 18px;
      padding: 15px;
      border-bottom: 1px solid #F7F7F7;
    }
  
    .discardBody{
      font-size: 15px;
      padding: 15px 15px 50px 15px;
      font-weight: 500;
      color: #555555;
    }
  
    .discardFooter{
      display: flex;
      float: right;
      padding: 0 15px 15px 15px;
      width: 482px;
      justify-content: flex-end;
      background-color: #F7F7F7;
    }


    .delContent{
      font-size: 14px;
    color: #E0291A;
    font-weight: 500;
    cursor: pointer;
    padding-top: 15px;
    }

    .delContent i{
      padding: 10px 10px 0 0;
      color:#E0291A;
    }

    .delContent:hover, .leftbtn1:hover{
      text-decoration: underline;
    }


    .discardbtn{
      width: 125px;
        font-weight: 500;
        height: 32px;
        background: #42BABD 0% 0% no-repeat padding-box;
        border-radius: 2px;
        border: transparent;
        margin-left: 20px;
        color: #424242;
     }
    
     .discardbtn1{
      background: transparent 0% 0% no-repeat padding-box;
      border-radius: 0px;
      opacity: 1;
      color: #424242;
      font-weight: 500;
      text-align: center;
      height: 32px;
      border: 1px solid #42BABD;
     }
    
    
  