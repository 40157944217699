.editContentsLabel {
  font-size: 14px;
  font-weight: 600;
  position: relative;
  margin-top: 30px;
  height: 18px;
  letter-spacing: 0px;
  color: #E0291A;
  opacity: 1;
  cursor:pointer;
  }

  .editContents {
    border: none;
    background: none;
    padding-right: 20px;
    font-weight:500;
    margin-top:20px;
    padding-bottom:5px
  }

  
  
  .editContents:hover {
    color: #E0291A;
  }


  .editID{
text-align: left;
font-weight:500;
letter-spacing: 0px;
color: #2C2C2C;
font-size:18px;
  }

  .toggleContentBtn{

    color:#939495;
    font-size:14px;

  }


  .contentFooter{
    bottom: 0;
    position: fixed;
    width: 1224px;
    height: 54px;
    background: #424242 0% 0% no-repeat padding-box;
    box-shadow: 0px -1px 10px #3333332e;
    border-radius: 4px 4px 0px 0px;
    z-index:999;

  }

 .leftbtn{
   float: left;
   padding:15px 0 0 15px;
   font-size: 14px;
 }
 .rightbtn{
   float:right;
   padding:15px 15px 0 0;
   font-size: 14px;
 }
  
 .leftbtn1{
  background: transparent 0% 0% no-repeat padding-box;
  border-radius: 2px;
  opacity: 1;
  border: transparent;
  color: white;
  font-weight: 500;
  text-align: center;
  height:32px
 }

 .leftbtn1:hover{
   text-decoration: underline;
 }

 .savebtn{
  width: 177px;
  height: 32px;
  background: #42BABD 0% 0% no-repeat padding-box;
  border-radius: 2px;
  border: transparent;
  margin-left: 10px;
  color: #FFFFFF;
 }

 .savebtn:hover,.discardbtn:hover,.discardbtn1:hover{
   background-color: #C6EAEB;
 }

 .discardbtn{
  width: 125px;
    font-weight: 500;
    height: 32px;
    background: #42BABD 0% 0% no-repeat padding-box;
    border-radius: 2px;
    border: transparent;
    margin-left: 20px;
    color: #424242;
 }

 .discardbtn1{
  background: transparent 0% 0% no-repeat padding-box;
  border-radius: 0px;
  opacity: 1;
  color: #424242;
  font-weight: 500;
  text-align: center;
  height: 32px;
  border: 1px solid #42BABD;
 }



 .contentp{
   font-size:11px;
   width:49px;
   background-color:#F5F5F1;
   position: relative;
   top:24px;
   left:9px;
 }

 .editDiv{
  display: inline-block; 
  width: 100%;
  padding: 15px 15px 0 18px;
 }

 .editParentDiv{
  background: #F5F5F1;
  box-shadow: 0px 1px 6px #3333332B;
  border-radius: 3px;
  margin-top:35px;
  font-size:14px;
 }

 .optionNum{
   font-size:16px;
   padding:15px;
   font-weight: 500;
   margin-left:5px;
 }


 .editQues{
  float: left;
  font-size: 12px;
  color: #424242;
  font-weight: 600;
  overflow: hidden;

 }


 .options{
   padding:10px 15px 0px 20px;
   font-weight:500;
   font-size:12px;
 }

 .speakerSvg{
   width:16px;
   cursor: pointer;
 }

 .exerciseSpeaker{
  border: 1px solid #E67320;
    padding: 8px;
    border-radius: 100px;
    position: relative;
    top: 45px;
 }

 .exerciseSpeaker1{
  color: #E67320;
     font-size: 14px;
     font-weight: 500;
     border: 1px solid #e67320;
     padding: 6px 10px;
     border-radius: 100px;
     margin: 15px;
  }


  .discardChanges{
    position: absolute;
    top: 40%;
    left:30%;
    background-color: white;
    box-shadow: 0px 3px 7px #0000004d;
    font-family: "Montserrat", sans-serif;
    width:482px;
    border-radius:4px;
    z-index:999;
  }


  .discardHeader{
    font-size: 18px;
    padding: 15px;
    border-bottom: 1px solid #F7F7F7;
  }

  .discardBody{
    font-size: 15px;
    padding: 15px 15px 50px 15px;
    font-weight: 500;
    color: #555555;
  }

  .discardFooter{
    display: flex;
    float: right;
    padding: 0 15px 15px 15px;
    width: 482px;
    justify-content: flex-end;
    background-color: #F7F7F7;
  }